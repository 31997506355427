.hud {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
  color: #ffffff;
  font-family: 'Orbitron', 'Arial', sans-serif;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
}

.hud-top {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}

.hud-bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
}

.connection-status {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 255, 0.3);
  display: flex;
  align-items: center;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-indicator.connected {
  background-color: #00ff00;
  box-shadow: 0 0 10px #00ff00;
}

.status-indicator.offline {
  background-color: #ffaa00;
  box-shadow: 0 0 10px #ffaa00;
}

.status-indicator.disconnected {
  background-color: #ff0000;
  box-shadow: 0 0 10px #ff0000;
}

.game-info {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 255, 0.3);
}

.player-id {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 255, 0.3);
}

.controls-help {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 255, 0.3);
  max-width: 400px;
}

.controls-help h3 {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  color: #00ffff;
}

.controls-grid {
  display: flex;
  gap: 20px;
}

.control-group {
  flex: 1;
}

.control-group h4 {
  margin: 0 0 8px 0;
  color: #00ccff;
  text-align: center;
  font-size: 0.9em;
  border-bottom: 1px solid rgba(0, 255, 255, 0.3);
  padding-bottom: 5px;
}

.control-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.85em;
}

.key {
  display: inline-block;
  background-color: rgba(0, 255, 255, 0.2);
  border: 1px solid rgba(0, 255, 255, 0.5);
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 8px;
  font-family: monospace;
  min-width: 20px;
  text-align: center;
}

.menu-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: #00ffff;
  border: 1px solid rgba(0, 255, 255, 0.5);
  border-radius: 5px;
  padding: 5px 15px;
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
  pointer-events: auto;
  margin-left: 10px;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
}

.menu-btn:hover {
  background-color: rgba(0, 40, 80, 0.8);
  border-color: rgba(0, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.menu-btn:active {
  transform: scale(0.95);
} 
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  font-family: 'Orbitron', sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.game-scene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.camera-mode-indicator {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #00ffff;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 255, 0.3);
  font-size: 0.9em;
  z-index: 100;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  pointer-events: none;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00ffff;
  background-color: #000;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
}

.loading h1 {
  margin-bottom: 20px;
  animation: pulse 1.5s infinite alternate;
}

.loading p {
  margin: 5px 0;
  font-size: 1.2rem;
  color: #aac8ff;
}

.loading .server-url {
  font-family: monospace;
  font-size: 1rem;
  padding: 5px 10px;
  background-color: rgba(0, 30, 60, 0.5);
  border: 1px solid rgba(100, 150, 255, 0.3);
  border-radius: 4px;
  color: #88ddff;
}

.loading .offline-button {
  margin-top: 30px;
  padding: 10px 20px;
  background: linear-gradient(to right, #373737, #1a1a1a);
  border: 1px solid rgba(255, 170, 0, 0.5);
  border-radius: 5px;
  color: #ffaa00;
  font-family: 'Orbitron', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0 0 5px rgba(255, 170, 0, 0.7);
  pointer-events: auto;
}

.loading .offline-button:hover {
  background: linear-gradient(to right, #464646, #292929);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 170, 0, 0.3);
}

.loading .offline-button:active {
  transform: translateY(1px);
}

.loading:after {
  content: '';
  width: 50px;
  height: 50px;
  margin-top: 30px;
  border: 5px solid rgba(0, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #00ffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  from {
    opacity: 0.8;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  }
  to {
    opacity: 1;
    text-shadow: 0 0 15px rgba(0, 255, 255, 1);
  }
}

.start-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #000000 0%, #0a1030 50%, #000000 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 10;
  overflow: auto;
  background-image: 
    radial-gradient(circle at 15% 15%, rgba(50, 70, 130, 0.2) 0%, transparent 30%),
    radial-gradient(circle at 85% 85%, rgba(100, 50, 180, 0.2) 0%, transparent 30%),
    url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='0.5' fill='%23ffffff11' /%3E%3C/svg%3E");
}

.start-screen::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, transparent 0%, #000000 70%);
  pointer-events: none;
}

.start-content {
  max-width: 800px;
  width: 90%;
  padding: 40px;
  background: rgba(10, 15, 30, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(100, 150, 255, 0.2);
  box-shadow: 0 0 30px rgba(50, 100, 255, 0.1),
              0 0 10px rgba(50, 100, 255, 0.05) inset;
  backdrop-filter: blur(10px);
  z-index: 2;
  animation: glow 10s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 30px rgba(50, 100, 255, 0.1),
                0 0 10px rgba(50, 100, 255, 0.05) inset;
  }
  50% {
    box-shadow: 0 0 40px rgba(100, 150, 255, 0.2),
                0 0 15px rgba(100, 150, 255, 0.1) inset;
  }
  100% {
    box-shadow: 0 0 30px rgba(50, 100, 255, 0.1),
                0 0 10px rgba(50, 100, 255, 0.05) inset;
  }
}

h1 {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(100, 150, 255, 0.8);
}

.highlight {
  color: #5f9fff;
}

.game-description {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #aac8ff;
}

.menu-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.menu-button {
  width: 250px;
  padding: 12px 20px;
  background: linear-gradient(to right, #2a3b65, #3a5395);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(100, 150, 255, 0.3);
}

.menu-button:hover {
  background: linear-gradient(to right, #3a5395, #4a63b5);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 40, 120, 0.4);
}

.menu-button:active {
  transform: translateY(1px);
}

.server-status {
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 170, 0, 0.3);
  border-radius: 5px;
  text-align: center;
}

.server-status p {
  color: #ffaa00;
  font-size: 0.85rem;
  margin: 0;
}

.game-info {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 10px;
}

.game-info h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #aac8ff;
}

.info-section {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(100, 150, 255, 0.2);
}

.info-section:last-child {
  border-bottom: none;
}

.info-section h3 {
  margin-bottom: 10px;
  color: #7aa5ff;
}

.controls-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.control-group {
  flex: 1;
  min-width: 200px;
}

.control-group h4 {
  margin-bottom: 10px;
  color: #aac8ff;
}

.key {
  display: inline-block;
  min-width: 25px;
  padding: 2px 5px;
  margin-right: 10px;
  background: rgba(100, 150, 255, 0.2);
  border: 1px solid rgba(100, 150, 255, 0.5);
  border-radius: 4px;
  text-align: center;
  font-family: monospace;
}

.back-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background: linear-gradient(to right, #2a3b65, #3a5395);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(100, 150, 255, 0.3);
}

.back-button:hover {
  background: linear-gradient(to right, #3a5395, #4a63b5);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 40, 120, 0.4);
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .start-content {
    padding: 20px;
  }
  
  h1 {
    font-size: 3rem;
  }
  
  .controls-list {
    flex-direction: column;
    gap: 15px;
  }
} 